import alhazar1 from '../images/Projects/Alhazar/1.png';
import alhazar2 from '../images/Projects/Alhazar/2.png';
import alhazarPortal1 from '../images/Projects/AlhazarPortal/1.jpg';
import alhazarPortal2 from '../images/Projects/AlhazarPortal/2.jpg';
import alhazarPortal3 from '../images/Projects/AlhazarPortal/3.jpg';
import affotax1 from '../images/Projects/Affotax/1.png';
import affotax2 from '../images/Projects/Affotax/2.png';
import affotaxPortal1 from '../images/Projects/AffotaxCRM/1.png';
import affotaxPortal2 from '../images/Projects/AffotaxCRM/2.jpeg';
import bizon1 from '../images/Projects/BisonLabs/1.png';
import bizon2 from '../images/Projects/BisonLabs/2.png';
import dwtf1 from '../images/Projects/DWTF/1.jpg';
import dwtf2 from '../images/Projects/DWTF/2.jpg';
import ec1 from '../images/Projects/EC/1.jpg';
import finalytics1 from '../images/Projects/Finalytics/1.png';
import finalytics2 from '../images/Projects/Finalytics/2.png';
import jks1 from '../images/Projects/JumaKhanSufi/1.jpg';
import kb1 from '../images/Projects/KasaBeeston/1.jpg';
import kb2 from '../images/Projects/KasaBeeston/2.jpg';
import mirha1 from '../images/Projects/MirhaAhmed/1.jpg';
import mySalon1 from '../images/Projects/MySalonManager/1.jpg';
import mySalonPortal1 from '../images/Projects/MySalonManagerPortal/1.jpg';
import mySalonPortal2 from '../images/Projects/MySalonManagerPortal/2.jpg';
import mySalonPortal3 from '../images/Projects/MySalonManagerPortal/3.jpg';
import speckPro1 from '../images/Projects/Speckpro/1.png';
import SpeckPro2 from '../images/Projects/Speckpro/2.png';
import walnutHash1 from '../images/Projects/Walnuthash/1.jpg';
import zta1 from '../images/Projects/ZTA/1.jpg';

const images = {
    alhazar1,
    alhazar2,
    alhazarPortal1,
    alhazarPortal2,
    alhazarPortal3,
    affotax1,
    affotax2,
    affotaxPortal1,
    affotaxPortal2,
    bizon1,
    bizon2,
    dwtf1,
    dwtf2,
    ec1,
    finalytics1,
    finalytics2,
    jks1,
    kb1,
    kb2,
    mirha1,
    mySalon1,
    mySalonPortal1,
    mySalonPortal2,
    mySalonPortal3,
    speckPro1,
    SpeckPro2,
    walnutHash1,
    zta1
};

export default images;